import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import InformationCircleIcon from '../_icons/InformationCircleIcon'

type AlertProps = {
	variant?: 'default' | 'warning' | 'error'
	className?: string
	['data-testid']?: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Alert = ({ variant = 'default', children, className, ...props }: AlertProps): JSX.Element => {
	return (
		<div
			{...props}
			className={classNames(
				'flex flex-row space-x-3 rounded-lg px-4 py-3',
				// `variant` Classes
				((): string => {
					switch (variant) {
						case 'error':
							return 'bg-error-100'
						case 'warning':
							return 'bg-alert-100'
						default:
							return 'bg-info-100'
					}
				})(),
				className
			)}
		>
			<div>
				<InformationCircleIcon
					className={classNames(
						'h-5 w-5',
						// `variant` Classes
						((): string => {
							switch (variant) {
								case 'error':
									return 'text-error-400'
								case 'warning':
									return 'text-alert-400'
								default:
									return 'text-info-300'
							}
						})()
					)}
				/>
			</div>
			<div>
				<p
					className="body-1-normal text-typography-primary"
					data-testid={formatDataTestId(
						props['data-testid'] ? [props['data-testid'], 'description'] : []
					)}
				>
					{children}
				</p>
			</div>
		</div>
	)
}

const MemoisedAlert = memo(Alert)

export default MemoisedAlert
