import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import Error404Notice from 'src/_shared/components/Error404Notice'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { OmniSessionStatus, OmniSessionStopReason } from 'src/_shared/enums/omni'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useCancelChargingSessionMutation } from 'src/_shared/mutations/sessions'
import {
	ROOT_USER_QUERY_KEY,
	UserQueryKey,
	useUserSessionLatestQuery
} from 'src/_shared/queries/user'

const SessionCancelScreen = (): JSX.Element => {
	const intl = useIntl()

	const queryClient = useQueryClient()

	const { isAuthenticated, accessToken } = useAuthContext()

	const { pathname } = useRouterLocation()

	const navigate = useRouterNavigate()

	const {
		data: session = null,
		error: userSessionLatestQueryError,
		status: userSessionLatestQueryStatus
	} = useUserSessionLatestQuery(
		{
			isRealTime: true
		},
		{
			staleTime: Infinity
		}
	)

	const {
		error: cancelChargingSessionMutationError,
		status: cancelChargingSessionMutationStatus,
		mutateAsync: cancelChargingSession
	} = useCancelChargingSessionMutation()

	const isSessionCancelLoading = [
		userSessionLatestQueryStatus,
		cancelChargingSessionMutationStatus
	].includes('pending')

	const isSessionNotFound = userSessionLatestQueryStatus === 'success' && session === null // Session does not exist

	const isSessionCancelError = [
		userSessionLatestQueryStatus,
		cancelChargingSessionMutationStatus
	].includes('error')

	const errorMessage =
		userSessionLatestQueryError?.response?.data.message ??
		cancelChargingSessionMutationError?.response?.data.message

	/**
	 * Default and SSO Modes: If user is not logged in, redirect to the `AccountLoginScreen`.
	 */
	useEffect((): void => {
		if (!isAuthenticated) {
			navigate({
				pathname: ScreenRoutePath.AccountLogin,
				search: `redirect=${pathname}`
			})
		}
	}, [isAuthenticated, pathname, navigate])

	/**
	 * Cancel and Redirect Logic
	 */
	useEffect((): void => {
		if (userSessionLatestQueryStatus === 'success' && session?._id) {
			const redirectToChargerScreen = (): void => {
				// Root is not previously in the history stack, hence we push it manually.
				window.history.pushState({}, '', ScreenRoutePath.Root)
				navigate(
					{
						pathname: [
							ScreenRoutePath.Charger,
							session.entity_code ?? '',
							session.location_uid ?? '',
							session.evse_uid ?? '',
							session.connector_uid ?? ''
						]
					},
					{ state: { isFromRedirect: true } }
				)
			}

			if (session._id && session.status === OmniSessionStatus.PendingPayment) {
				void cancelChargingSession(
					{
						sessionId: session._id
					},
					{
						onSuccess: (): void => {
							// Optimistic update done to prevent `PreChargingView` from jumping into the `ChargingView`
							queryClient.setQueryData(
								[ROOT_USER_QUERY_KEY, UserQueryKey.UserSessionLatest, accessToken],
								{
									...session,
									status: OmniSessionStatus.StartFailure,
									stop_reason: OmniSessionStopReason.PaymentCancelled
								}
							)
						},
						onSettled: (): void => {
							redirectToChargerScreen()
						}
					}
				)
			} else {
				console.debug('[SessionCancel] Session does not need to be cancelled:', session)
				redirectToChargerScreen()
			}
		}
	}, [
		accessToken,
		queryClient,
		session,
		userSessionLatestQueryStatus,
		cancelChargingSession,
		navigate
	])

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			hideBottomBar
		>
			{isSessionCancelLoading && (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			)}
			{isSessionNotFound && <Error404Notice />}
			{isSessionCancelError && (
				<Notice
					type="error"
					header={intl.formatMessage({
						id: 'Common.GenericErrorTitle',
						defaultMessage: 'Oops! Something Went Wrong'
					})}
					description={errorMessage}
				/>
			)}
		</ScreenContainer>
	)
}
export default SessionCancelScreen
