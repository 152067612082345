import { useCallback } from 'react'
import EnterOtpViewComponent from 'src/_shared/components/EnterOtpView'
import {
	useUserMobileNumberOtpRequestMutation,
	useUserMobileNumberOtpVerifyMutation
} from 'src/_shared/mutations/auth'

import useCountdownTimer from '../../../_shared/hooks/useCountdownTimer'
import { FormFieldKey } from '../enums'
import { AccountForgotPasswordScreenCommonViewProps } from '../types'

type EnterOtpViewProps = AccountForgotPasswordScreenCommonViewProps

const EnterOtpView = ({
	formValues,
	setFormValues,
	onNext: handleNext
}: EnterOtpViewProps): JSX.Element => {
	const countryCode = formValues[FormFieldKey.CountryCode]

	const mobileNumber = formValues[FormFieldKey.MobileNumber]

	const otp = formValues[FormFieldKey.Otp]

	const [seconds, resetCountdownTimer] = useCountdownTimer()

	const {
		error: otpResendError,
		isError: isOtpResendError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserMobileNumberOtpRequestMutation()

	const {
		error: otpVerifyError,
		isError: isOtpVerifyError,
		isPending: isOtpVerifyPending,
		mutateAsync: verifyOtp,
		reset: resetUserOtpVerifyMutation
	} = useUserMobileNumberOtpVerifyMutation()

	const errorMessage =
		otpVerifyError?.response?.data.message ?? otpResendError?.response?.data.message

	const handleOtpChange = useCallback(
		(value: string): void => {
			setFormValues(
				(values): Record<FormFieldKey, string> => ({
					...values,
					[FormFieldKey.Otp]: value
				})
			)
			if (isOtpVerifyError && value !== otp) {
				resetUserOtpVerifyMutation()
			}
		},
		[isOtpVerifyError, otp, resetUserOtpVerifyMutation, setFormValues]
	)

	const handleRequestOtpClick = useCallback((): void => {
		void requestOtp(
			{
				mobileNumber: countryCode + mobileNumber
			},
			{
				onSuccess: (): void => {
					resetUserOtpRequestMutation()
					resetCountdownTimer()
				}
			}
		)
	}, [countryCode, mobileNumber, requestOtp, resetCountdownTimer, resetUserOtpRequestMutation])

	const handleOtpFilled = useCallback(
		(value: string): void => {
			void verifyOtp(
				{
					mobileNumber: countryCode + mobileNumber,
					otp: value
				},
				{
					onSuccess: (response): void => {
						setFormValues(
							(values): Record<FormFieldKey, string> => ({
								...values,
								[FormFieldKey.ResetToken]: response.data.token
							})
						)
						resetUserOtpVerifyMutation()
						handleNext?.()
					}
				}
			)
		},
		[countryCode, mobileNumber, handleNext, resetUserOtpVerifyMutation, setFormValues, verifyOtp]
	)

	return (
		<EnterOtpViewComponent
			dataTestIdPrefix="afps"
			otpRecipient={
				<span
					data-testid="afps-eov-text-mobile-number"
					className="body-1-semibold mt-1 block text-primary-800"
				>
					{countryCode} {mobileNumber}
				</span>
			}
			secondsCountdown={seconds}
			isOtpVerifyPending={isOtpVerifyPending}
			isRequestOtpPending={isRequestOtpPending}
			inputOtpProps={{
				id: FormFieldKey.Otp,
				name: FormFieldKey.Otp,
				value: otp,
				disabled: isOtpVerifyPending,
				error: isOtpVerifyError || isOtpResendError,
				description: errorMessage,
				autoFocus: true,
				onChange: handleOtpChange,
				onFilled: handleOtpFilled
			}}
			onRequestOtpClick={handleRequestOtpClick}
		/>
	)
}

export default EnterOtpView
