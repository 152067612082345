import { ReactNode, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Lottie from 'react-lottie'
import { Link } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Alert from 'src/_shared/components/Alert'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import { NoticeProps } from 'src/_shared/components/Notice'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import Skeleton from 'src/_shared/components/Skeleton'
import Spinner from 'src/_shared/components/Spinner'
import { APP_MODE, BRAND, CONTACT_US_NUMBER } from 'src/_shared/constants/env'
import { AppMode, Brand } from 'src/_shared/enums/env'
import {
	OmniConnectorPowerType,
	OmniConnectorStatus,
	OmniSessionChargingPeriodDimensionType,
	OmniSessionStatus,
	OmniSessionStopReason
} from 'src/_shared/enums/omni'
import {
	useStopChargingSessionMutation,
	useStopChargingSessionTransientMutation
} from 'src/_shared/mutations/sessions'
import {
	LOTTIE_CHARGING_ANIMATION_DATA,
	LOTTIE_SPINNER_ANIMATION_DATA
} from 'src/screens/ChargerScreen/constants'

import { useIsUserSubscribed } from '../../../_shared/hooks/useIsUserSubscribed'
import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerItemLists } from '../hooks/useChargerItemLists'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import { getLatestChargingPeriodDimension, getSessionStateOfCharge } from '../utils'
import ChargingHeader from './ChargingHeader'
import DetailsGrid from './DetailsGrid'
import DetailsRow from './DetailsRow'
import Footer from './Footer'
import OpeningHoursModal from './OpeningHoursModal'
import StateOfChargeCircle from './StateOfChargeCircle'
import TariffModal from './TariffModal'
import TermsAndConditionsBottomRender from './TermsAndConditionsBottomRender'

type ChargingViewProps = ChargerScreenCommonViewProps

enum ChargingViewState {
	Finishing,
	Ongoing,
	Starting
}

const ChargingView = ({
	isAuthenticated = false,
	routeParams,
	onNext: handleNext,
	updateView
}: ChargingViewProps): JSX.Element => {
	const [chargingViewState, setChargingViewState] = useState<ChargingViewState>(
		ChargingViewState.Starting
	)

	const [isTariffModalOpen, setIsTariffModalOpen] = useState<boolean>(false)

	const [isOpeningHoursModalOpen, setIsOpeningHoursModalOpen] = useState<boolean>(false)

	const intl = useIntl()

	const { location, evse, connector, locationQueryStatus, connectorQueryStatus } =
		useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const stopChargingSessionMutation = useStopChargingSessionMutation()

	const stopChargingSessionTransientMutation = useStopChargingSessionTransientMutation()

	const isTransientFlow = APP_MODE === AppMode.Transient

	const {
		error: stopChargingSessionError,
		mutateAsync: stopChargingSession,
		reset: resetStopChargingSessionMutation
	} = isTransientFlow ? stopChargingSessionTransientMutation : stopChargingSessionMutation

	const isUserSubscribed = useIsUserSubscribed(routeParams.cpoEntityCode)

	const isLocationPending = locationQueryStatus === 'pending'

	const isConnectorPending = connectorQueryStatus === 'pending'

	const isChargerSessionDetailsPending = chargerSessionDetailsQueryStatus === 'pending'

	const isLoading = isLocationPending || isChargerSessionDetailsPending

	const isConnectorDown =
		!!connector?.status &&
		[OmniConnectorStatus.OutOfOrder, OmniConnectorStatus.Unknown].includes(connector.status)

	const handleSwipeComplete = useCallback((): void => {
		if (session?._id) {
			setChargingViewState(ChargingViewState.Finishing)
			void stopChargingSession(
				{ sessionId: session._id },
				{
					// Clear the modal so that the user can re-attempt to stop the charger
					// should any issues occur (e.g. connector is `OUTOFORDER` or `UNKNOWN`).
					onError: (): void => {
						resetStopChargingSessionMutation()
						setChargingViewState(ChargingViewState.Ongoing)
					}
				}
			)
		}
	}, [session?._id, resetStopChargingSessionMutation, stopChargingSession])

	const toggleTariffModal = useCallback((): void => {
		setIsTariffModalOpen((isOpen): boolean => !isOpen)
	}, [])

	const toggleOpeningHoursModal = useCallback((): void => {
		setIsOpeningHoursModalOpen((isOpen): boolean => !isOpen)
	}, [])

	const { gridItemList, rowsItemList, tariffInformationItemList } = useChargerItemLists({
		location,
		connector,
		activeTariff: null,
		session,
		isAuthenticated,
		isUserSubscribed,
		toggleTariffModal
	})

	/**
	 * Connector has transitioned from Available/Preparing to Charging and Session is Active.
	 */
	useEffect((): void => {
		// If the `ENERGY` dimension is present, then the session should currently be in charging.
		const hasEnergyDimension = getLatestChargingPeriodDimension(
			session?.charging_periods,
			OmniSessionChargingPeriodDimensionType.Energy
		)
		if (
			isUserChargingSession &&
			chargingViewState === ChargingViewState.Starting &&
			session?.status === OmniSessionStatus.Active &&
			hasEnergyDimension
		) {
			setChargingViewState(ChargingViewState.Ongoing)
		}
	}, [chargingViewState, isUserChargingSession, session?.charging_periods, session?.status])

	/**
	 * Handle Session Status Error
	 */
	useEffect((): void => {
		if (
			isUserChargingSession &&
			!!session?.status &&
			[OmniSessionStatus.StartFailure, OmniSessionStatus.StopFailure].includes(session.status)
		) {
			const errorViewProps = ((): NoticeProps => {
				if (session.status === OmniSessionStatus.StartFailure) {
					switch (session.stop_reason) {
						case OmniSessionStopReason.ChargerTimeout:
							return {
								type: 'timeout',
								header: intl.formatMessage({
									id: 'ChargingView.TitleChargerTimeout',
									defaultMessage: 'Charger Timeout'
								}),
								description: intl.formatMessage({
									id: 'ChargingView.DescriptionChargerTimeout',
									defaultMessage:
										'Charger has failed to start due to a timeout. Please try again or contact support if the issue persists.'
								})
							}
						case OmniSessionStopReason.StartPaymentFailureInsufficientFunds:
							return {
								type: 'warning',
								header: intl.formatMessage({
									id: 'ChargingView.TitleInsufficientFunds',
									defaultMessage: 'Your Account Has Insufficient Balance'
								})
							}
						default:
							return {
								type: 'error',
								header: intl.formatMessage({
									id: 'ChargingView.TitleStartChargeFailure',
									defaultMessage: 'Failed to Start Charge'
								}),
								description: intl.formatMessage({
									id: 'ChargingView.DescriptionStartChargeFailure',
									defaultMessage: 'Please try again later'
								})
							}
					}
				}
				// Stop Failure from `session` or from the `stopChargingSession` mutation.
				else if (session.status === OmniSessionStatus.StopFailure || stopChargingSessionError) {
					return {
						type: 'error',
						header: intl.formatMessage({
							id: 'ChargingView.TitleStopChargeFailure',
							defaultMessage: 'Failed to Stop Charge'
						}),
						description: intl.formatMessage({
							id: 'ChargingView.DescriptionStopChargeFailure',
							defaultMessage: 'Please try again later'
						})
					}
				}
				// Generic Error
				return {
					type: 'error',
					header: intl.formatMessage({
						id: 'ChargingView.TitleOopsFailure',
						defaultMessage: 'Oops! Something Went Wrong'
					}),
					description: intl.formatMessage({
						id: 'ChargingView.DescriptionOopsFailure',
						defaultMessage: 'Please try again later'
					})
				}
			})()

			updateView?.(ChargerScreenViewItemKey.ErrorView, errorViewProps)
		}
	}, [
		intl,
		isUserChargingSession,
		session?.status,
		session?.stop_reason,
		stopChargingSessionError,
		updateView
	])

	/**
	 * Move to Post-Charging View when session is finishing or completed.
	 */
	useEffect((): void => {
		// If the `PARKING_TIME` dimension is present, then the session should be done with charging and is finishing.
		const hasParkingTimeDimension = !!getLatestChargingPeriodDimension(
			session?.charging_periods,
			OmniSessionChargingPeriodDimensionType.ParkingTime
		)
		if (
			isUserChargingSession &&
			session?.status &&
			// Session is already completed/stopped.
			([OmniSessionStatus.Completed, OmniSessionStatus.Stopped].includes(session.status) ||
				// Session is active but finished charging.
				(session.status === OmniSessionStatus.Active && hasParkingTimeDimension))
		) {
			handleNext?.()
		}
	}, [
		chargingViewState,
		isUserChargingSession,
		session?.charging_periods,
		session?.status,
		handleNext
	])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.ChargingView
	})

	// Show a spinner if the somehow enters the Charging View when
	// the `connector` has not yet been fetched, e.g. refreshing.
	if (isConnectorPending) {
		return (
			<div className="flex flex-grow flex-col items-center justify-center pb-6">
				<Spinner />
			</div>
		)
	}
	return (
		<>
			{/* Header and Content Body */}
			<div>
				<ChargingHeader
					className="mb-1"
					loading={isLoading}
					location={location}
					evse={evse}
					connector={connector}
					topRender={((): JSX.Element | null => {
						const renderStateOfChargeCircle = (): JSX.Element => {
							const stateOfChargeDimension = getSessionStateOfCharge(session)
							const percentage =
								typeof stateOfChargeDimension?.volume === 'number'
									? stateOfChargeDimension.volume
									: null
							return (
								<StateOfChargeCircle
									className="mb-3"
									variant={((): 'active' | 'inactive' | 'progress' => {
										if (chargingViewState === ChargingViewState.Starting) {
											return 'inactive'
										} else if (percentage !== null) {
											return 'progress'
										}
										return 'active'
									})()}
									centerRender={
										percentage !== null && (
											<>
												<p
													data-testid="cs-cv-text-charger-progress-percentage"
													className="text-[24px] font-semibold leading-7 text-white"
												>
													{percentage}%
												</p>
												<p className="caption-2-medium mb-1 leading-none text-white">
													<FormattedMessage
														id="ChargingView.TitleStateOfCharge"
														defaultMessage="State of Charge"
													/>
												</p>
											</>
										)
									}
									percentage={percentage}
									isCharging={true}
								/>
							)
						}

						switch (BRAND) {
							case Brand.Kineta:
								return renderStateOfChargeCircle()
							case Brand.SpGroup: {
								// Show State of Charge only for DC Chargers
								if (connector?.power_type === OmniConnectorPowerType.Dc) {
									return renderStateOfChargeCircle()
								}
								return null
							}
							case Brand.Evme: {
								return (
									<Skeleton loading={isLoading}>
										<img className="mb-4 h-10 min-w-10 max-w-36" src={location?.operator?.logo} />
										<div className="mb-4 h-20 w-20">
											<Lottie
												isClickToPauseDisabled={true}
												isPaused={chargingViewState === ChargingViewState.Starting}
												options={{
													loop: true,
													autoplay: false,
													animationData: LOTTIE_CHARGING_ANIMATION_DATA
												}}
											/>
										</div>
									</Skeleton>
								)
							}
							default:
								return null
						}
					})()}
					bottomRender={
						<Skeleton loading={isLoading}>
							<div>
								<button data-testid="cs-cv-btn-toggle-tariff-modal" onClick={toggleTariffModal}>
									<span className="body-1-normal text-typography-primary underline">
										<FormattedMessage
											id="ChargingView.ButtonTextFeeDetails"
											defaultMessage="Fee Details"
										/>
									</span>
								</button>
								<span className="text-typography-tertiary">&#x2022;</span>
								<button
									data-testid="cs-cv-btn-toggle-opening-hours-modal"
									onClick={toggleOpeningHoursModal}
								>
									<span className="body-1-normal text-typography-primary underline">
										<FormattedMessage
											id="ChargingView.ButtonTextOpeningHours"
											defaultMessage="Opening Hours"
										/>
									</span>
								</button>
							</div>
						</Skeleton>
					}
				/>
				<DetailsGrid location={location} itemList={gridItemList} loading={isLoading} />
			</div>
			{/* Footer */}
			<Footer
				data-testid="cs-cv-footer"
				className="flex-grow space-y-6 pt-3"
				topRender={
					<div className="flex w-full flex-grow flex-col">
						<DetailsRow itemList={rowsItemList} loading={isLoading} />
						{isConnectorDown && (
							<Alert
								variant="warning"
								className="mt-2"
								data-testid="cs-cv-alert-connector-ooo-unknown"
							>
								<FormattedMessage
									id="ChargingView.AlertChargerOffline"
									defaultMessage="This charger may be offline or out-of-order. If you are encountering issues with the session, please check the <a>FAQs</a> on how to resolve them."
									values={{
										a: (chunks): JSX.Element => (
											<Link to={ScreenRoutePath.AccountHelp}>{chunks}</Link>
										)
									}}
								/>
							</Alert>
						)}
					</div>
				}
				buttonType="swipeable"
				buttonProps={{
					children: intl.formatMessage({
						id: 'ChargingView.ButtonTextSlideToStopCharging',
						defaultMessage: 'Slide to Stop Charging'
					}),
					className: 'w-full',
					disabled: chargingViewState === ChargingViewState.Starting,
					onSwipeComplete: handleSwipeComplete
				}}
				bottomRender={((): ReactNode => {
					if (isTransientFlow) {
						return (
							!!CONTACT_US_NUMBER && (
								<p>
									<FormattedMessage
										id="ChargingView.FooterTextHelp"
										defaultMessage="<p1>Didn't get our charging start email?</p1> <p2>Check your spam folder or call us at our <a>hotline</a> for assistance.</p2>"
										values={{
											p1: (chunks): JSX.Element => (
												<p className="body-1-normal text-center text-typography-tertiary">
													{chunks}
												</p>
											),
											p2: (chunks): JSX.Element => (
												<p className="body-1-normal text-center text-typography-tertiary">
													{chunks}
												</p>
											),
											a: (chunks): JSX.Element => <a href={`tel:${CONTACT_US_NUMBER}`}>{chunks}</a>
										}}
									/>
								</p>
							)
						)
					} else {
						switch (BRAND) {
							case Brand.Evme:
								return (
									<Link className="body-1-semibold text-center" to={ScreenRoutePath.AccountHelp}>
										<FormattedMessage
											id="ChargingView.FooterLinkHavingIssues"
											defaultMessage="Having issues?"
										/>
									</Link>
								)
							default:
								return <TermsAndConditionsBottomRender />
						}
					}
				})()}
			/>
			<PoweredByFooter className="pb-6" />
			{/* Tariff Modal */}
			<TariffModal
				location={location}
				activeTariff={session?.tariff ?? null}
				open={isTariffModalOpen}
				itemList={tariffInformationItemList}
				onClose={toggleTariffModal}
			/>
			{/* Opening Hours Modal */}
			<OpeningHoursModal
				location={location}
				open={isOpeningHoursModalOpen}
				onClose={toggleOpeningHoursModal}
			/>
			{/* Loading Modal */}
			<Modal open={chargingViewState !== ChargingViewState.Ongoing}>
				<ModalCard className="flex max-w-62 flex-col items-center !pb-10 !pt-8">
					{((): JSX.Element => {
						switch (BRAND) {
							case Brand.Evme:
								return (
									<div className="mb-4 h-20 w-20">
										<Lottie
											isClickToPauseDisabled={true}
											options={{
												loop: true,
												autoplay: true,
												animationData: LOTTIE_SPINNER_ANIMATION_DATA
											}}
										/>
									</div>
								)
							default:
								return <Spinner className="mb-4" />
						}
					})()}
					<p className="body-2-light text-center">
						{chargingViewState === ChargingViewState.Starting ? (
							<FormattedMessage
								id="ChargingView.DescriptionStartCharge"
								defaultMessage="Attempting to start charge"
							/>
						) : (
							<FormattedMessage
								id="ChargingView.DescriptionStopCharge"
								defaultMessage="Attempting to stop charge"
							/>
						)}
					</p>
				</ModalCard>
			</Modal>
		</>
	)
}

export default ChargingView
