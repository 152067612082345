import { OmniSession } from 'src/_shared/types/omni'

import { PaymentMethodCode } from '../enums/payments'

// FUTURE TO-DO: FE should not be doing this.
interface BasePaymentBreakdown {
	/**
	 * Summation of Energy and Per Min Costs
	 */
	subTotalCost: number
	totalCost: number
	isCostFree: boolean
}

interface PaymentBreakdownEnergyCost {
	totalKwh: number
	pricePerKwh: number
	energyCost: number
}

interface PaymentBreakdownWithoutPricePerMinCost {
	hasPricePerMinCost: false
}

interface PaymentBreakdownWithPricePerMinCost {
	hasPricePerMinCost: true
	totalChargingTime: number
	pricePerMinTime: number
	pricePerMinCost: number
}

interface PaymentBreakdownWithoutIdleCost {
	hasIdleCost: false
}

interface PaymentBreakdownWithIdleCost {
	hasIdleCost: true
	totalIdleTime: number
	pricePerIdleTime: number
	idleCost: number
}

export type PaymentBreakdown = BasePaymentBreakdown &
	PaymentBreakdownEnergyCost &
	(PaymentBreakdownWithoutPricePerMinCost | PaymentBreakdownWithPricePerMinCost) &
	(PaymentBreakdownWithoutIdleCost | PaymentBreakdownWithIdleCost)

const formatTotalTimeInHoursToMinutes = (hours = 0): number => {
	// Set precision up to 4 d.p. to avoid rounding-up too aggressively.
	const minutes = Number((hours * 60).toFixed(4))
	return Math.ceil(minutes)
}

/**
 * Returns the breakdown of costs incurred in a session.
 * @param {OmniSession} session The session to get the payment breakdown for.
 * @returns {PaymentBreakdown} The payment breakdown of the session which accounts for taxes.
 */
export const getPaymentBreakdown = (session?: OmniSession): PaymentBreakdown => {
	const isCostFree =
		!!session?.payment_method_code &&
		[PaymentMethodCode.FreeOfCharge, PaymentMethodCode.Subscription].includes(
			session.payment_method_code
		)

	// 1. Energy Costs
	const energyCostBreakdown = ((): PaymentBreakdownEnergyCost => {
		const totalKwh = (() => {
			// For new sessions data that has the new `volumes` field.
			if (session?.volumes?.total_energy_charged_kwh !== undefined) {
				return session.volumes.total_energy_charged_kwh
			}
			// In case of bad/old data, fallback to `kwh` field.
			if (session?.kwh) {
				return session.kwh
			}
			return 0
		})()

		const pricePerKwh = session?.tariff?.price_per_kwh?.incl_vat ?? 0

		const maxEnergyCostInclVat = session?.tariff?.max_price?.incl_vat ?? 0

		const energyCost =
			session?.costs_by_type?.energy?.incl_vat !== undefined
				? session.costs_by_type.energy.incl_vat
				: // In case of bad/old data, energy cost is calculated here.
					Math.min(maxEnergyCostInclVat, totalKwh * pricePerKwh)

		return {
			totalKwh,
			pricePerKwh,
			energyCost
		}
	})()

	// 2. Price Per Min Costs
	const pricePerMinBreakdown = (():
		| PaymentBreakdownWithoutPricePerMinCost
		| PaymentBreakdownWithPricePerMinCost => {
		if (session?.tariff?.price_per_min) {
			const totalChargingTime = formatTotalTimeInHoursToMinutes(
				session.volumes?.total_time_charged_hr
			)

			const pricePerMinTime = session.tariff.price_per_min.incl_vat ?? 0

			const pricePerMinCost = session.costs_by_type?.time?.incl_vat ?? 0

			return {
				hasPricePerMinCost: true,
				totalChargingTime,
				pricePerMinTime,
				pricePerMinCost
			}
		}
		return {
			hasPricePerMinCost: false
		}
	})()

	// 3. Sum of Energy Costs and Price Per Min Costs (Including Taxes)
	const subTotalCost =
		energyCostBreakdown.energyCost +
		(pricePerMinBreakdown.hasPricePerMinCost ? pricePerMinBreakdown.pricePerMinCost : 0)

	// 4. Idle Costs (Including Taxes)
	const idleCostBreakdown = ((): PaymentBreakdownWithoutIdleCost | PaymentBreakdownWithIdleCost => {
		if (session?.tariff?.price_per_min_parked) {
			const pricePerIdleTime = session.tariff.price_per_min_parked.incl_vat ?? 0

			const totalIdleTime = formatTotalTimeInHoursToMinutes(
				session.volumes?.total_parking_time_charged_hr
			)

			const idleCost = session.costs_by_type?.parking_time?.incl_vat ?? 0

			return {
				hasIdleCost: true,
				totalIdleTime,
				pricePerIdleTime,
				idleCost
			}
		}
		return { hasIdleCost: false }
	})()

	const totalCost = session?.total_cost?.incl_vat ?? 0

	return {
		...energyCostBreakdown,
		...pricePerMinBreakdown,
		...idleCostBreakdown,
		subTotalCost,
		totalCost,
		isCostFree
	}
}
