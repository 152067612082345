import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryParams } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import { NoticeProps } from 'src/_shared/components/Notice'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import CardIcon from 'src/_shared/components/_icons/CardIcon'
import ChevronRightIcon from 'src/_shared/components/_icons/ChevronRightIcon'
import TouchNGoLogoImage from 'src/_shared/components/_images/TouchNGoLogoImage'
import { BRAND } from 'src/_shared/constants/env'
import { TRANSIENT_SESSION_ID_KEY } from 'src/_shared/constants/storage'
import { Brand } from 'src/_shared/enums/env'
import { OmniError } from 'src/_shared/enums/omni'
import { PaymentMethodCode, PaymentTypeCode } from 'src/_shared/enums/payments'
import { useLocalStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useStartChargingSessionTransientMutation } from 'src/_shared/mutations/sessions'
import { formatCurrencyValue, formatDataTestId } from 'src/_shared/utils/string'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'
import Footer from './Footer'

type TransientPaymentMethodsViewProps = ChargerScreenCommonViewProps

interface TransientPaymentMethodOption {
	title: string
	paymentMethodCode: PaymentMethodCode
	icon: ReactNode
}

const TransientPaymentMethodsView = ({
	routeParams,
	onNext: handleNext,
	updateView
}: TransientPaymentMethodsViewProps): JSX.Element => {
	const [selectedPaymentMethodCode, setSelectedPaymentMethodCode] =
		useState<PaymentMethodCode | null>(null)

	const intl = useIntl()

	const [, setTransientSessionId] = useLocalStorageItem(TRANSIENT_SESSION_ID_KEY)

	const [{ email = '' }] = useQueryParams<ChargerScreenQueryParams>()

	const { connector, activeTariff } = useChargerDetails({
		...routeParams,
		enableActiveTariffQuery: true
	})

	const { session, isUserChargingSession, resetChargerSessionDetailsQueries } =
		useChargerSessionDetails(routeParams)

	const {
		isPending: isStartChargingSessionTransientPending,
		isSuccess: isStartChargingSessionTransientSuccess,
		mutateAsync: startChargingSessionTransient,
		reset: resetStartChargingSessionTransientMutation
	} = useStartChargingSessionTransientMutation()

	const isStartChargingSessionTransientLoading =
		isStartChargingSessionTransientPending || isStartChargingSessionTransientSuccess

	const handlePaymentMethodClick = useCallback(
		(paymentMethodCode: PaymentMethodCode) => (): void => {
			setSelectedPaymentMethodCode(paymentMethodCode)
		},
		[]
	)

	const closeNoticeModal = useCallback((): void => {
		if (!isStartChargingSessionTransientLoading) {
			setSelectedPaymentMethodCode(null)
		}
	}, [isStartChargingSessionTransientLoading])

	const handleNextClick = useCallback((): void => {
		if (selectedPaymentMethodCode) {
			void startChargingSessionTransient(
				{
					...routeParams,
					email,
					paymentMethodCode: selectedPaymentMethodCode,
					paymentTypeCode: PaymentTypeCode.Redirect,
					redirectUrl: `${window.location.href}&payment_success=true`,
					backUrl: `${window.location.href}&payment_success=false`
				},
				{
					onError: ({ response }): void => {
						const errorViewProps = ((): NoticeProps => {
							const errorCode = response?.data.error?.code
							switch (errorCode) {
								case OmniError.PaymentInsufficientFunds:
									return {
										type: 'warning',
										header: intl.formatMessage({
											id: 'TransientPaymentMethodsView.TitleInsufficientFunds',
											defaultMessage: 'Your Account Has Insufficient Balance'
										})
									}
								default:
									return {
										type: 'error',
										header: intl.formatMessage({
											id: 'TransientPaymentMethodsView.TitleStartChargeFailure',
											defaultMessage: 'Failed to Start Charge'
										}),
										description: intl.formatMessage({
											id: 'TransientPaymentMethodsView.DescriptionStartChargeFailure',
											defaultMessage: 'Please try again later'
										})
									}
							}
						})()
						updateView?.(ChargerScreenViewItemKey.ErrorView, errorViewProps)
					},
					onSuccess: (response): void => {
						const sessionId = response.data.data?.session_id ?? ''

						const paymentRedirectUrl = response.data.data?.payment_url

						const handleStartChargingSuccess = async (): Promise<void> => {
							console.debug(
								`[${ChargerScreenViewItemKey.TransientPaymentMethodsView}] Session ID:`,
								sessionId
							)
							setTransientSessionId(sessionId)
							if (paymentRedirectUrl) {
								console.debug(
									`[${ChargerScreenViewItemKey.TransientPaymentMethodsView}] Redirecting to:`,
									paymentRedirectUrl
								)
								window.location.href = paymentRedirectUrl
							} else {
								await resetChargerSessionDetailsQueries()
								handleNext?.()
							}
						}
						void handleStartChargingSuccess()
					}
				}
			)
		}
	}, [
		email,
		intl,
		routeParams,
		selectedPaymentMethodCode,
		handleNext,
		resetChargerSessionDetailsQueries,
		setTransientSessionId,
		startChargingSessionTransient,
		updateView
	])

	const paymentMethodOptions = useMemo((): {
		category: string
		options: TransientPaymentMethodOption[]
	}[] => {
		const cardPaymentMethodOption: TransientPaymentMethodOption = {
			title: intl.formatMessage({
				id: 'TransientPaymentMethodsView.ListOptionCard',
				defaultMessage: 'Card'
			}),
			paymentMethodCode: PaymentMethodCode.Emv,
			icon: <CardIcon className="h-8 w-8 text-typography-primary" />
		}

		const stripePaymentMethodOption: TransientPaymentMethodOption = {
			title: intl.formatMessage({
				id: 'TransientPaymentMethodsView.ListOptionCard',
				defaultMessage: 'Card'
			}),
			paymentMethodCode: PaymentMethodCode.Stripe,
			icon: <CardIcon className="h-8 w-8 text-typography-primary" />
		}

		const tngPaymentMethodOption: TransientPaymentMethodOption = {
			title: intl.formatMessage({
				id: 'TransientPaymentMethodsView.ListOptionTngWallet',
				defaultMessage: 'TnG Wallet'
			}),
			paymentMethodCode: PaymentMethodCode.TouchNGo,
			icon: <TouchNGoLogoImage className="h-8 w-8" />
		}

		switch (BRAND) {
			case Brand.Kineta: {
				return [
					{
						category: intl.formatMessage({
							id: 'TransientPaymentMethodsView.ListTitleDebitCreditCard',
							defaultMessage: 'Debit or Credit Card'
						}),
						options: [cardPaymentMethodOption]
					},
					{
						category: intl.formatMessage({
							id: 'TransientPaymentMethodsView.ListTitleInstantPayment',
							defaultMessage: 'Instant Payment'
						}),
						options: [tngPaymentMethodOption]
					}
				]
			}
			default:
				return [
					{
						category: intl.formatMessage({
							id: 'TransientPaymentMethodsView.ListTitleDebitCreditCard',
							defaultMessage: 'Debit or Credit Card'
						}),
						options: [stripePaymentMethodOption]
					}
				]
		}
	}, [intl])

	/**
	 * Handle resetting of states when restoring from back/forward cache.
	 */
	useEffect((): (() => void) => {
		const resetViewState = (event: PageTransitionEvent): void => {
			if (event.persisted) {
				setSelectedPaymentMethodCode(null)
				resetStartChargingSessionTransientMutation()
			}
		}
		window.addEventListener('pageshow', resetViewState)
		return (): void => {
			window.removeEventListener('pageshow', resetViewState)
		}
	}, [resetStartChargingSessionTransientMutation])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.TransientPaymentMethodsView
	})

	return (
		<>
			<div className="flex-grow">
				{paymentMethodOptions.map(({ category, options }, i): JSX.Element => {
					return (
						<div key={i} className="[&:not(:last-child)]:mb-5">
							<h1 className="mb-3 text-typography-primary">{category}</h1>
							{options.map(({ title, paymentMethodCode, icon }, j): JSX.Element => {
								return (
									<Fragment key={j}>
										<div
											data-testid={formatDataTestId(['cs-tpmv-btn-select', paymentMethodCode])}
											className="flex cursor-pointer flex-row items-center"
											onClick={handlePaymentMethodClick(paymentMethodCode)}
										>
											<div className="min-w-8 py-5">{icon}</div>
											<div className="flex-grow px-4 py-5">
												<p className="body-2-semibold text-typography-primary">{title}</p>
											</div>
											<div className="py-5">
												<ChevronRightIcon className="h-6 w-6 text-typography-primary" />
											</div>
										</div>
										{j < options.length - 1 && (
											<div className="my-0.5 border-b-[1px] border-divider-primary" />
										)}
									</Fragment>
								)
							})}
						</div>
					)
				})}
			</div>
			{/* Footer */}
			<Footer
				buttonProps={{
					className: 'hidden'
				}}
			/>
			<PoweredByFooter className="pb-6" />
			{/* Notice Modal */}
			<Modal open={!!selectedPaymentMethodCode} onClose={closeNoticeModal}>
				<ModalCard className="flex flex-col items-center">
					<h1 className="mb-2 text-center">
						<FormattedMessage
							id="TransientPaymentMethodsView.ModalTitleNotice"
							defaultMessage="Notice"
						/>
					</h1>
					<FormattedMessage
						id="TransientPaymentMethodsView.ModalDescriptionPreAuthHold"
						defaultMessage="<p1>There will be a pre-authorization hold of <b>{preAuthValue}</b> on your credit/debit card/e-wallet.</p1> <p2>This hold will be released once the transaction is complete, typically within 24 hours, but it might take up to 7 days based on your bank's policy.</p2>"
						values={{
							b: (chunks): JSX.Element => <span className="body-2-semibold">{chunks}</span>,
							p1: (chunks): JSX.Element => (
								<p className="body-2-normal mb-2 text-center">{chunks}</p>
							),
							p2: (chunks): JSX.Element => (
								<p className="body-2-normal mb-8 text-center">{chunks}</p>
							),
							preAuthValue: ((): string => {
								const maxPrice = activeTariff?.max_price?.incl_vat
								return formatCurrencyValue(maxPrice, activeTariff?.currency, 2)
							})()
						}}
					/>
					<div className="flex w-full flex-col space-y-4">
						<Button
							data-testid="cs-tpmv-btn-next"
							variant="primary"
							disabled={isStartChargingSessionTransientLoading}
							loading={isStartChargingSessionTransientLoading}
							onClick={handleNextClick}
						>
							<FormattedMessage
								id="TransientPaymentMethodsView.ModalButtonTextUnderstand"
								defaultMessage="I Understand"
							/>
						</Button>
						<Button
							data-testid="cs-tpmv-btn-cancel"
							variant="secondary"
							disabled={isStartChargingSessionTransientLoading}
							onClick={closeNoticeModal}
						>
							<FormattedMessage
								id="TransientPaymentMethodsView.ModalButtonTextCancel"
								defaultMessage="Cancel"
							/>
						</Button>
					</div>
				</ModalCard>
			</Modal>
		</>
	)
}

export default TransientPaymentMethodsView
