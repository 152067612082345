import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Alert from 'src/_shared/components/Alert'
import Button from 'src/_shared/components/Button'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import QrScannerIcon from 'src/_shared/components/_icons/QrScannerIcon'
import { APP_MODE, STRAPI_URL } from 'src/_shared/constants/env'
import { AppMode } from 'src/_shared/enums/env'
import useCheckInCpos, { CpoData } from 'src/_shared/hooks/useCheckInCpos'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'

const CheckInScreen = (): JSX.Element => {
	const [selectedCpo, setSelectedCpo] = useState<CpoData | null>(null)

	const navigate = useRouterNavigate()

	const { brandData } = useStrapiContext()

	const { cpos, isCpoDataLoading } = useCheckInCpos()

	const { logoDark } = brandData?.attributes ?? {}

	const isStandaloneCpo = cpos.length <= 1

	const logoUrl = useMemo((): string | null => {
		if (STRAPI_URL) {
			return STRAPI_URL + logoDark?.data?.attributes.url
		}
		return null
	}, [logoDark])

	const handleSelectCpo = (cpo: CpoData) => (): void => {
		setSelectedCpo(cpo)
	}

	const handleScanQrCodeClick = (): void => {
		navigate({
			pathname: ScreenRoutePath.CheckInQrScanner,
			search: selectedCpo?.entityCode ? `cpoEntityCodes=${selectedCpo.entityCode}` : undefined
		})
	}

	const handleEnterConnectorIdClick = (): void => {
		navigate({
			pathname: ScreenRoutePath.CheckInManual,
			search: selectedCpo?.entityCode ? `cpoEntityCodes=${selectedCpo.entityCode}` : undefined
		})
	}

	return (
		<ScreenContainer
			contentViewProps={{ className: 'px-5 py-6' }}
			topBarProps={{
				// Hide Top-Left Back Arrow for Transient
				leftRender: APP_MODE === AppMode.Transient ? <></> : null
			}}
			hideTopBar={isStandaloneCpo}
			hideBottomBar
		>
			{isCpoDataLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					{isStandaloneCpo ? (
						// Standalone CPO Landing View
						<div className="flex flex-grow flex-col items-center">
							{/* Brand Logo */}
							{logoUrl && (
								<img
									src={logoUrl}
									alt={logoDark?.data?.attributes.alternativeText ?? ''}
									className="m-6 h-14 w-auto"
								/>
							)}
							{/* Description */}
							<div className="flex flex-grow flex-col items-center justify-center space-y-10">
								<QrScannerIcon className="h-24 w-24 text-primary-700" />
								<p className="body-2-semibold max-w-72 text-center text-typography-primary">
									<FormattedMessage
										id="CheckInScreen.DescriptionScanOrEnterCode"
										defaultMessage="Please scan the QR code or enter the Connector ID to get started"
									/>
								</p>
							</div>
						</div>
					) : (
						// Grid List of CPOs
						<div className="flex-grow">
							<h1 className="mb-2 text-typography-primary">
								<FormattedMessage
									id="CheckInScreen.HeaderSelectCpo"
									defaultMessage="Select Charge Point Operator"
								/>
							</h1>
							<p className="body-2-normal mb-5 text-typography-primary">
								<FormattedMessage
									id="CheckInScreen.DescriptionSelectCpoScanQrCode"
									defaultMessage="Please select the preferred charge point operator to scan the QR Code."
								/>
							</p>
							<div className="grid grid-cols-3 gap-x-2 gap-y-2">
								{cpos.map((cpo, index): JSX.Element => {
									const { name, entityCode, imageUrl } = cpo
									const isSelected = selectedCpo?.entityCode === entityCode
									return (
										<button
											data-testid={`cis-btn-select-cpo-${index}`}
											key={index}
											className={classNames(
												'flex h-24 cursor-pointer flex-col items-center justify-center gap-y-2 rounded-2xl border-2 p-3',
												isSelected ? 'border-success-400' : 'border-divider-primary'
											)}
											onClick={handleSelectCpo(cpo)}
										>
											<div
												data-testid={`cis-img-cpo-${index}`}
												className="flex h-7 flex-col justify-center"
											>
												<img className="max-h-7 max-w-20" src={imageUrl} />
											</div>
											<div className="w-full">
												<p
													data-testid={`cis-text-cpo-name-${index}`}
													className="body-1-semibold truncate text-center text-typography-primary"
												>
													{name}
												</p>
											</div>
										</button>
									)
								})}
							</div>
						</div>
					)}
					{/* Buttons */}
					<div className="sticky bottom-0 flex flex-col space-y-4 bg-gradient-to-b from-grayscale-100/90 to-grayscale-100 pt-6">
						{/* Info-Notice for No QR Code Support */}
						{selectedCpo?.disableScanQrButton && (
							<Alert data-testid="cis-alert-scan-qr-disabled">
								<FormattedMessage
									id="CheckInScreen.InformationDescriptionNoQrCode"
									defaultMessage="This CPO does not have QR code support. Please enter the Connector ID instead."
								/>
							</Alert>
						)}
						<Button
							data-testid="cis-btn-scan-qr"
							variant="primary"
							disabled={(!isStandaloneCpo && !selectedCpo) || selectedCpo?.disableScanQrButton}
							onClick={handleScanQrCodeClick}
						>
							<FormattedMessage
								id="CheckInScreen.ButtonTextScanQrCode"
								defaultMessage="Scan QR Code"
							/>
						</Button>
						<Button
							data-testid="cis-btn-enter-connecter-id"
							variant="ghost"
							disabled={!isStandaloneCpo && !selectedCpo}
							onClick={handleEnterConnectorIdClick}
						>
							<FormattedMessage
								id="CheckInScreen.ButtonTextEnterConnectorId"
								defaultMessage="Enter Connector ID"
							/>
						</Button>
					</div>
					{/* Footer */}
					<PoweredByFooter className="mt-6" />
				</>
			)}
		</ScreenContainer>
	)
}

export default CheckInScreen
