import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniDayNumberOfWeek } from 'src/_shared/enums/omni'
import { OmniLocation, OmniLocationOpeningTimesPeriodValues } from 'src/_shared/types/omni/location'

import { PERIOD_TIME_FORMAT } from '../constants'

interface OpeningHoursModalProps {
	location: OmniLocation | null
	open?: boolean
	onClose?: () => void
}

const DATE_TIME_FORMAT = `MMM DD YYYY, ${TIME_FORMAT}`

const generateExceptionalPeriodElements = (
	{ period_begin: periodBegin, period_end: periodEnd }: OmniLocationOpeningTimesPeriodValues,
	index: number
): JSX.Element => {
	const startDateTime = dayjs(periodBegin)
	const endDateTime = dayjs(periodEnd)
	const isWithinSameDay = startDateTime.isSame(endDateTime, 'date')
	const isWithinSameYear = startDateTime.isSame(endDateTime, 'year')
	return (
		<div key={index} className="body-2-normal [&:not(:last-child)]:mb-2">
			<span className="body-2-medium">
				{startDateTime.format(
					!isWithinSameDay && isWithinSameYear ? `MMM DD, ${TIME_FORMAT}` : DATE_TIME_FORMAT
				)}
			</span>{' '}
			-{' '}
			<span className="body-2-medium">
				{endDateTime.format(isWithinSameDay ? TIME_FORMAT : DATE_TIME_FORMAT)}
			</span>
		</div>
	)
}

const OpeningHoursModal = ({
	location,
	open,
	onClose: handleClose
}: OpeningHoursModalProps): JSX.Element => {
	const intl = useIntl()

	const {
		regular_hours: regularHours = [],
		exceptional_openings: exceptionalOpenings = [],
		exceptional_closings: exceptionalClosings = [],
		twentyfourseven: isTwentyFourSeven
	} = location?.opening_times ?? {}

	const dayNumberToStringMap = useMemo((): Record<OmniDayNumberOfWeek, string> => {
		return {
			[OmniDayNumberOfWeek.Monday]: intl.formatMessage({
				id: 'OpeningHoursModal.DayMonday',
				defaultMessage: 'Monday'
			}),
			[OmniDayNumberOfWeek.Tuesday]: intl.formatMessage({
				id: 'OpeningHoursModal.DayTuesday',
				defaultMessage: 'Tuesday'
			}),
			[OmniDayNumberOfWeek.Wednesday]: intl.formatMessage({
				id: 'OpeningHoursModal.DayWednesday',
				defaultMessage: 'Wednesday'
			}),
			[OmniDayNumberOfWeek.Thursday]: intl.formatMessage({
				id: 'OpeningHoursModal.DayThursday',
				defaultMessage: 'Thursday'
			}),
			[OmniDayNumberOfWeek.Friday]: intl.formatMessage({
				id: 'OpeningHoursModal.DayFriday',
				defaultMessage: 'Friday'
			}),
			[OmniDayNumberOfWeek.Saturday]: intl.formatMessage({
				id: 'OpeningHoursModal.DaySaturday',
				defaultMessage: 'Saturday'
			}),
			[OmniDayNumberOfWeek.Sunday]: intl.formatMessage({
				id: 'OpeningHoursModal.DaySunday',
				defaultMessage: 'Sunday'
			})
		}
	}, [intl])

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalCard>
				{/* Opening Hours */}
				<h1 className="mb-2">
					<FormattedMessage
						id="OpeningHoursModal.ModalTitleOpeningHours"
						defaultMessage="Opening Hours"
					/>
				</h1>
				{isTwentyFourSeven ? (
					<div className="flex justify-between space-x-3">
						<span className="body-2-normal">
							<FormattedMessage id="OpeningHoursModal.DayMonday" defaultMessage="Monday" /> -{' '}
							<FormattedMessage id="OpeningHoursModal.DaySunday" defaultMessage="Sunday" />
						</span>
						<span className="body-2-medium">
							<FormattedMessage
								id="OpeningHoursModal.LabelValueTwentyFourHours"
								defaultMessage="24 Hours"
							/>
						</span>
					</div>
				) : (
					regularHours.map(
						(
							{
								period_begin: periodBegin,
								period_end: periodEnd,
								weekday = OmniDayNumberOfWeek.Monday
							},
							index
						): JSX.Element => {
							const label = dayNumberToStringMap[weekday]
							const periodBeginFormatted = dayjs(periodBegin, PERIOD_TIME_FORMAT).format(
								TIME_FORMAT
							)
							const periodEndFormatted = dayjs(periodEnd, PERIOD_TIME_FORMAT).format(TIME_FORMAT)
							const value = `${periodBeginFormatted} - ${periodEndFormatted}`
							return (
								<div
									className="flex justify-between space-x-3 [&:not(:last-child)]:mb-2"
									key={index}
								>
									<span className="body-2-normal">{label}</span>
									<span className="body-2-medium">{value}</span>
								</div>
							)
						}
					)
				)}
				{/* Exceptional Openings */}
				{exceptionalOpenings.length > 0 && (
					<>
						<div className="my-5 border-b border-divider-primary" />
						<h1 className="mb-2">
							<FormattedMessage
								id="OpeningHoursModal.ModalTitleExceptionalOpenings"
								defaultMessage="Exceptional Openings"
							/>
						</h1>
						{exceptionalOpenings.map(generateExceptionalPeriodElements)}
					</>
				)}
				{/* Exceptional Closings */}
				{exceptionalClosings.length > 0 && (
					<>
						<div className="my-5 border-b border-divider-primary" />
						<h1 className="mb-2">
							<FormattedMessage
								id="OpeningHoursModal.ModalTitleExceptionalClosings"
								defaultMessage="Opening Hours"
							/>
						</h1>
						{exceptionalClosings.map(generateExceptionalPeriodElements)}
					</>
				)}
				<Button data-testid="cs-tm-btn-close" className="mt-8 w-full" onClick={handleClose}>
					<FormattedMessage id="OpeningHoursModal.ModalButtonTextClose" defaultMessage="Close" />
				</Button>
			</ModalCard>
		</Modal>
	)
}

const MemoisedOpeningHoursModal = memo(OpeningHoursModal)

export default MemoisedOpeningHoursModal
