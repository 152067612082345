import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import { OmniTariff } from 'src/_shared/types/omni'
import { OmniLocation } from 'src/_shared/types/omni/location'

import { TariffInformationItem } from '../types'

interface TariffModalProps {
	itemList: TariffInformationItem[]
	location: OmniLocation | null
	activeTariff: OmniTariff | null
	open?: boolean
	onClose?: () => void
}

const TariffModal = ({
	itemList,
	location,
	activeTariff,
	open,
	onClose: handleClose
}: TariffModalProps): JSX.Element => {
	return (
		<Modal open={open} onClose={handleClose}>
			<ModalCard>
				<h1 className="mb-2">
					<FormattedMessage id="TariffModal.ModalTitleFeeDetails" defaultMessage="Fee Details" />
				</h1>
				<div className="mb-2">
					<p className="caption-3-medium">{location?.address}</p>
				</div>
				{/* Tariff Alt-Text */}
				{activeTariff?.tariff_alt_text &&
					activeTariff.tariff_alt_text.length > 0 &&
					activeTariff.tariff_alt_text.map(({ text }, index): JSX.Element | null => {
						const displayText = text?.trim()
						if (displayText) {
							return (
								<p key={index} className="body-1-normal mb-2">
									{displayText}
								</p>
							)
						}
						return null
					})}
				<div className="mb-2 border-b border-divider-primary" />
				{/* Tariff Information Item List */}
				{itemList.map(({ label, labelSuffix, value }, index): JSX.Element => {
					return (
						<div className={index < itemList.length - 1 ? 'mb-3' : 'mb-8'} key={index}>
							<div>
								<span className="body-2-normal">
									{label} {labelSuffix}
								</span>
							</div>
							<div className="body-2-medium min-h-6">{value}</div>
						</div>
					)
				})}
				<Button data-testid="cs-tm-btn-close" className="w-full" onClick={handleClose}>
					<FormattedMessage id="TariffModal.ModalButtonTextClose" defaultMessage="Close" />
				</Button>
			</ModalCard>
		</Modal>
	)
}

const MemoisedTariffModal = memo(TariffModal)

export default MemoisedTariffModal
