import { LANGUAGE_KEY } from '../constants/storage'
import { Language } from '../enums/env'
import { OmniCountryCode, OmniCountryCurrencyCode } from '../enums/omni'
import { isValidLanguage } from './string'

interface CurrencyDetails {
	countryCode?: OmniCountryCode
	/**
	 * Can be an empty string.
	 */
	prefix: string
	/**
	 * Can be an empty string.
	 */
	suffix: string
	symbol?: string
}

/**
 * Returns the currency details of the specified `currencyCode`.
 * @param {OmniCountryCurrencyCode} currencyCode The currency code (of the country).
 * @returns {CurrencyDetails} The details of the currency (of the country).
 */
export const getCurrencyDetails = (currencyCode?: OmniCountryCurrencyCode): CurrencyDetails => {
	switch (currencyCode) {
		case OmniCountryCurrencyCode.Malaysia:
			return {
				countryCode: OmniCountryCode.Malaysia,
				prefix: 'RM ', // Trailing whitespace is intended.
				suffix: '',
				symbol: 'RM'
			}
		case OmniCountryCurrencyCode.Singapore:
			return {
				countryCode: OmniCountryCode.Singapore,
				prefix: '$',
				suffix: '',
				symbol: '$'
			}
		case OmniCountryCurrencyCode.Thailand: {
			const language = ((): Language => {
				const value = localStorage.getItem(LANGUAGE_KEY)
				if (isValidLanguage(value)) {
					return value
				}
				return Language.English
			})()
			return {
				countryCode: OmniCountryCode.Thailand,
				prefix: '',
				suffix: language === Language.Thai ? ' บาท' : ' THB', // Trailing whitespace is intended.
				symbol: '฿'
			}
		}
		default:
			return { prefix: '$', suffix: '', symbol: '$' }
	}
}

/**
 * Returns the tax acronym of the specified `countryCode`.
 * @param {OmniCountryCode} countryCode The country code.
 * @returns {string} The tax acronym used in the country.
 */
export const getTaxAcronym = (countryCode?: OmniCountryCode): string => {
	switch (countryCode) {
		case OmniCountryCode.Malaysia:
			return 'SST'
		case OmniCountryCode.Singapore:
			return 'GST'
		case OmniCountryCode.Thailand:
			// FUTURE TODO: Consider if this needs to be translated to Thai
			return 'VAT'
		default:
			return 'Tax'
	}
}
