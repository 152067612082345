export enum OmniConnectorFormat {
	Cable = 'CABLE',
	Socket = 'SOCKET'
}

export enum OmniConnectorPowerType {
	AcOnePhase = 'AC_1_PHASE',
	AcTwoPhase = 'AC_2_PHASE',
	AcTwoPhaseSplit = 'AC_2_PHASE_SPLIT',
	AcThreePhase = 'AC_3_PHASE',
	Dc = 'DC'
}

export enum OmniConnectorPrivilegeStatus {
	Private = 'PRIVATE',
	Privileged = 'PRIVILEGED',
	Public = 'PUBLIC'
}

/**
 * OCPI 3.0-2 Functional Use Cases > 5.4.12 `ConnectorType` `OpenEnum`
 * @see {@link https://evroaming.org/wp-content/uploads/2024/10/OCPI-3.0-v1-review.zip}
 */
export enum OmniConnectorStandard {
	// Common EV Charging Connector Types
	Chademo = 'CHADEMO',
	Iec62196T1 = 'IEC_62196_T1',
	Iec62196T1Combo = 'IEC_62196_T1_COMBO',
	Iec62196T2 = 'IEC_62196_T2',
	Iec62196T2Combo = 'IEC_62196_T2_COMBO',
	Mcs = 'MCS',
	SaeJ3400 = 'SAE_J3400',
	TeslaS = 'TESLA_S',
	// Domestic and Industrial Connector Types
	DomesticA = 'DOMESTIC_A',
	DomesticB = 'DOMESTIC_B',
	DomesticC = 'DOMESTIC_C',
	DomesticD = 'DOMESTIC_D',
	DomesticE = 'DOMESTIC_E',
	DomesticF = 'DOMESTIC_F',
	DomesticG = 'DOMESTIC_G',
	DomesticH = 'DOMESTIC_H',
	DomesticI = 'DOMESTIC_I',
	DomesticJ = 'DOMESTIC_J',
	DomesticK = 'DOMESTIC_K',
	DomesticL = 'DOMESTIC_L',
	DomesticM = 'DOMESTIC_M',
	DomesticN = 'DOMESTIC_N',
	DomesticO = 'DOMESTIC_O',
	Iec603092Single16 = 'IEC_60309_2_SINGLE_16',
	Iec603092Three16 = 'IEC_60309_2_THREE_16',
	Iec603092Three32 = 'IEC_60309_2_THREE_32',
	Iec603092Three64 = 'IEC_60309_2_THREE_64',
	Nema520 = 'NEMA_5_20',
	Nema630 = 'NEMA_6_30',
	Nema650 = 'NEMA_6_50',
	Nema1030 = 'NEMA_10_30',
	Nema1050 = 'NEMA_10_50',
	Nema1430 = 'NEMA_14_30',
	Nema1450 = 'NEMA_14_50',
	//  Legacy and Novelty Connector Types
	GbtAc = 'GBT_AC',
	GbtDc = 'GBT_DC',
	Iec62196T3A = 'IEC_62196_T3A',
	Iec62196T3C = 'IEC_62196_T3C',
	PantographBottomUp = 'PANTOGRAPH_BOTTOM_UP',
	PantographTopDown = 'PANTOGRAPH_TOP_DOWN',
	TeslaR = 'TESLA_R'
}

/**
 * OCPI 3.0-2 Functional Use Cases > 5.4.42. `Status` `enum`, 5.4.34. `PresenceStatus` `enum`
 * @see {@link https://evroaming.org/wp-content/uploads/2024/10/OCPI-3.0-v1-review.zip}
 */
export enum OmniConnectorStatus {
	/**
	 * The EVSE/Connector is able to start a new charging session.
	 */
	Available = 'AVAILABLE',
	/**
	 * The EVSE/Connector is not accessible because of a physical barrier, i.e. a car.
	 */
	Blocked = 'BLOCKED',
	/**
	 * The EVSE/Connector is in use.
	 */
	Charging = 'CHARGING',
	/**
	 * The EVSE/Connector has stopped charging the EV but is still plugged into the EV and (usually) pending removal.
	 */
	Finishing = 'FINISHING',
	/**
	 * The EVSE/Connector is not yet active, or temporarily not available for use, but not broken or defect.
	 */
	Inoperative = 'INOPERATIVE',
	/**
	 * The EVSE/Connector is currently out of order, some part/components may be broken/defect.
	 */
	OutOfOrder = 'OUTOFORDER',
	/**
	 * The EVSE is not currently present but it is used to be present in the past.
	 */
	Planned = 'PLANNED',
	/**
	 * The EVSE/Connector is (usually) plugged into the EV but not charging the vehicle.
	 */
	Preparing = 'PREPARING',
	/**
	 * The EVSE is not currently present but it is used to be present in the past.
	 */
	Removed = 'REMOVED',
	/**
	 * The EVSE/Connector is reserved for a particular EV driver and is unavailable for other drivers.
	 */
	Reserved = 'RESERVED',
	/**
	 * No status information available (also used when offline).
	 */
	Unknown = 'UNKNOWN'
}
