import { memo, ReactNode } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import AccountScreenHeader from '../AccountScreenHeader'
import Button from '../Button'
import InputOtp, { InputOtpProps } from '../InputOtp'
import Spinner from '../Spinner'

interface EnterOtpViewProps {
	otpRecipient?: ReactNode
	inputOtpProps?: Omit<InputOtpProps, 'data-testid' | 'dataTestIdPrefix'>
	secondsCountdown?: number
	isOtpVerifyPending?: boolean
	isRequestOtpPending?: boolean
	className?: string
	dataTestIdPrefix?: string
	onRequestOtpClick?: () => void
}

const EnterOtpView = ({
	otpRecipient,
	inputOtpProps,
	secondsCountdown = 0,
	isOtpVerifyPending = false,
	isRequestOtpPending = false,
	className,
	dataTestIdPrefix,
	onRequestOtpClick: handleRequestOtpClick
}: EnterOtpViewProps): JSX.Element => {
	return (
		<div className={classNames('flex flex-grow flex-col', className)}>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader
					className="mb-[-4px]" // Account for margin in `subTitle`.
					title="Enter OTP"
					subTitle={
						<>
							We have sent a verification code to
							{otpRecipient}
						</>
					}
				/>
				<div className="flex flex-col items-center space-y-5">
					<InputOtp
						{...inputOtpProps}
						data-testid={formatDataTestId([dataTestIdPrefix, 'input-otp'])}
						dataTestIdPrefix={formatDataTestId([dataTestIdPrefix, 'input-otp'])}
						className={classNames('w-full', inputOtpProps?.className)}
					/>
					<p
						data-testid={formatDataTestId([dataTestIdPrefix, 'eov-text-seconds-countdown'])}
						className="body-1-normal text-center text-typography-primary"
					>
						{secondsCountdown} Sec
					</p>
					{isOtpVerifyPending && <Spinner className="h-5 w-5" />}
				</div>
			</div>
			<div className="flex flex-col items-center">
				<p className="body-1-normal text-center text-typography-secondary">
					Didn&apos;t receive the OTP code?
				</p>
				<Button
					data-testid={formatDataTestId([dataTestIdPrefix, 'eov-btn-resend-otp'])}
					variant="ghost"
					className="w-fit border-none"
					disabled={secondsCountdown > 0 || isOtpVerifyPending || isRequestOtpPending}
					onClick={handleRequestOtpClick}
					textProps={{
						className: 'uppercase body-1-semibold'
					}}
				>
					Resend New Code
				</Button>
			</div>
		</div>
	)
}

const MemoisedEnterOtpView = memo(EnterOtpView)

export default MemoisedEnterOtpView
