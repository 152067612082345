import {
	ChangeEvent,
	DetailedHTMLProps,
	InputHTMLAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

export type InputOtpProps = {
	value?: string
	digits?: number
	error?: boolean
	description?: string
	onChange?: (value: string) => void
	/**
	 * The callback to fire when `value` has reached the maximum length.
	 * @param {string} value The `value` with length equal to `digits`.
	 */
	onFilled?: (value: string) => void
	dataTestIdPrefix?: string
} & Pick<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'autoFocus' | 'className' | 'disabled' | 'id' | 'name'
>

const InputOtp = ({
	className: containerClassName,
	value,
	digits = 6,
	disabled,
	error,
	description,
	onChange: handleChange,
	onFilled: handleFilled,
	dataTestIdPrefix = '',
	...inputProps
}: InputOtpProps): JSX.Element => {
	const [inputValue, setInputValue] = useState<string>(value ? value : '')

	const controlledValue = useMemo((): string => {
		if (value !== undefined) {
			return value
		}
		return inputValue
	}, [inputValue, value])

	const handleInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			const formattedValue = event.currentTarget.value.replace(/[^0-9]*/g, '').substring(0, digits)
			setInputValue(formattedValue)
			handleChange?.(formattedValue)
		},
		[digits, handleChange]
	)

	useEffect((): void => {
		if (inputValue.length === digits) {
			handleFilled?.(inputValue)
		}
	}, [digits, inputValue, handleFilled])

	return (
		<div
			className={classNames(
				// Base Classes
				'flex flex-col space-y-3',
				containerClassName
			)}
		>
			<div
				className={classNames(
					// Base Classes
					'group relative grid h-14 gap-1',
					// Grid Cols
					`grid-cols-${digits}`
				)}
			>
				{/* Hidden Input */}
				{/* This will get clicked on and can still be filled in. */}
				<input
					{...inputProps}
					inputMode="numeric"
					className={classNames(
						// Base Classes
						'absolute z-10 h-full w-full opacity-0',
						// `disabled` Classes
						disabled ? 'cursor-not-allowed' : null
					)}
					value={controlledValue}
					disabled={disabled}
					onChange={handleInputChange}
				/>
				{Array.from(Array(digits)).map((_, index): JSX.Element => {
					const digit = ((): string | null => {
						if (controlledValue.charAt(index)) {
							return controlledValue.charAt(index)
						} else if (index === controlledValue.length) {
							return '|'
						}
						return null
					})()
					return (
						<div
							key={index}
							className={classNames(
								// Base Classes
								'flex flex-col items-center justify-center rounded-lg bg-grayscale-300',
								// Input Filled Classes
								!!digit?.match(/^[0-9]$/) && !error ? 'border-2 border-primary-800' : null,
								// Cursor Classes
								digit === '|'
									? 'group-has-[input:focus]:border-2 group-has-[input:focus]:border-primary-400/30'
									: null,
								// Error Classes
								error ? 'border-2 border-error-300' : null
							)}
						>
							<span
								// For example, "ars-eov-input-otp" is the `dataTestIdPrefix` prop.
								data-testid={formatDataTestId([dataTestIdPrefix, `text-digit-${index}`])}
								className={classNames(
									// Base Classes
									'text-xl',
									// Error Classes
									error ? '!text-error-300' : null,
									// Cursor Classes
									digit === '|'
										? 'animate-blink invisible font-light group-has-[input:focus]:visible'
										: 'font-semibold',
									// `disabled` Classes
									disabled ? 'text-typography-tertiary' : 'text-typography-primary'
								)}
							>
								{digit}
							</span>
						</div>
					)
				})}
			</div>
			{/* Description */}
			{description && (
				<p
					className={classNames(
						// Base Classes
						'body-1-normal text-center',
						// Error Class
						error ? 'text-error-300' : 'text-typography-secondary'
					)}
				>
					{description}
				</p>
			)}
		</div>
	)
}

const MemoisedInputOtp = memo(InputOtp)

export default MemoisedInputOtp
